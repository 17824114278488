<template>
 <div>
  <MyInfoNormal v-if="!$store.state.user.elderModel"/>
  <MyInfoElder v-else/>
 </div>
</template>

<script>
import MyInfoNormal from './my-info-normal.vue'
import MyInfoElder from './my-info-elder.vue'
 export default {
   name: '',
   components: {
     MyInfoNormal,
     MyInfoElder

   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>
