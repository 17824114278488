<template>
  <div class="info-container">
    <van-nav-bar fixed>
      <template #left>
        <div @click="$router.go(-1)" class="hd-left">
          <div></div>
        </div>
      </template>
      <template #title>
        <div class="hd-title">个人信息</div>
      </template>
    </van-nav-bar>
    <input type="file" hidden ref="file" @change="onFileChange" />
    <div class="profile-info info" @click="$refs.file.click()">
      <span>头像:</span>
      <div class="middle">
        <img :src="userInfo.avatar" alt="" />
      </div>
      <div class="right"></div>
      <div class="underline"></div>
    </div>
    <div class="name-info info" @click="isUpdateNameShow = true">
      <span>姓名:</span>
      <div class="middle">{{ userInfo.realName }}</div>
      <div class="right"></div>
      <div class="underline"></div>
    </div>
    <div class="sex-info info" @click="isUpdateGenderShow = true">
      <span>性别:</span>
      <div class="middle">{{ userInfo.sex == 1 ? '男' : '女' }}</div>
      <div class="right"></div>
      <div class="underline"></div>
    </div>
    <div class="date-info info" @click="isUpdateBirthdayShow = true">
      <span>出生日期:</span>
      <div class="middle">{{ userInfo.birthday }}</div>
      <div class="right"></div>
      <div class="underline"></div>
    </div>
    <div class="phone-info info" @click="isUpdatePhoneShow = true">
      <span>手机号:</span>
      <div class="middle">{{ userInfo.phone }}</div>
      <div class="right"></div>
      <div class="underline"></div>
    </div>
    <div class="mail-info info" @click="isUpdateMailShow = true">
      <span>邮箱:</span>
      <div class="middle">{{ userInfo.email }}</div>
      <div class="right"></div>
      <div class="underline"></div>
    </div>

    <!-- 编辑昵称 -->
    <van-popup
      v-model="isUpdateNameShow"
      style="height: 100%"
      position="bottom"
    >
      <update-name
        v-if="isUpdateNameShow"
        v-model="userInfo"
        @succeed="getEditUserInfo"
        @close="isUpdateNameShow = false"
      />
    </van-popup>
    <!-- /编辑昵称 -->

    <!-- 编辑性别 -->
    <van-popup v-model="isUpdateGenderShow" position="bottom">
      <update-gender
        @succeed="getEditUserInfo"
        v-if="isUpdateGenderShow"
        v-model="userInfo"
        @close="isUpdateGenderShow = false"
      />
    </van-popup>
    <!-- /编辑性别 -->
    <!-- 编辑生日 -->
    <van-popup v-model="isUpdateBirthdayShow" position="bottom">
      <update-birthday
        @succeed="getEditUserInfo"
        v-if="isUpdateBirthdayShow"
        v-model="userInfo"
        @close="isUpdateBirthdayShow = false"
      />
    </van-popup>
    <!-- /编辑生日 -->
    <!-- 编辑手机号 -->
    <van-popup
      v-model="isUpdatePhoneShow"
      style="height: 100%"
      position="bottom"
    >
      <update-phone
        v-if="isUpdatePhoneShow"
        v-model="userInfo"
        @succeed="getEditUserInfo"
        @close="isUpdatePhoneShow = false"
      />
    </van-popup>
    <!-- /编辑手机号 -->
    <!-- 编辑邮箱 -->
    <van-popup
      v-model="isUpdateMailShow"
      style="height: 100%"
      position="bottom"
    >
      <update-mail
        v-if="isUpdateMailShow"
        v-model="userInfo"
        @succeed="getEditUserInfo"
        @close="isUpdateMailShow = false"
      />
    </van-popup>
    <!-- /编辑邮箱 -->
    <!-- 编辑头像 -->
    <van-popup
      v-model="isUpdatePhotoShow"
      position="bottom"
      style="height: 100%"
    >
      <update-photo
        @succeed="getEditUserInfo"
        v-if="isUpdatePhotoShow"
        :img="img"
        v-model="userInfo"
        @close="isUpdatePhotoShow = false"
      />
    </van-popup>
    <!-- /编辑头像 -->
  </div>
</template>

<script>
import { getEditUserInfo } from '@/api/me'
import UpdateName from './update-name.vue'
import UpdateGender from './update-gender.vue'
import UpdateBirthday from './update-birthday.vue'
import UpdatePhone from './update-phone.vue'
import UpdateMail from './update-mail.vue'
import UpdatePhoto from './update-photo.vue'
export default {
  name: '',
  components: {
    UpdateName,
    UpdateGender,
    UpdateBirthday,
    UpdatePhone,
    UpdateMail,
    UpdatePhoto
  },
  data() {
    return {
      userInfo: {},
      isUpdateNameShow: false,
      isUpdateGenderShow: false,
      isUpdateBirthdayShow: false,
      isUpdatePhoneShow: false,
      isUpdateMailShow: false,
      isUpdatePhotoShow: false,
      img: null // 预览的图片
    }
  },
  computed: {},
  created() {
    this.getEditUserInfo()
  },
  mounted() {},
  methods: {
    getEditUserInfo() {
      getEditUserInfo().then((res) => {
        this.userInfo = res.result
      })
    },
    onFileChange() {
      // 获取文件对象
      const file = this.$refs.file.files[0]

      // 基于文章对象获取 blob 数据
      this.img = window.URL.createObjectURL(file)

      // 展示预览图片弹出层
      this.isUpdatePhotoShow = true

      // file-input 如果选了同一个文件不会触发 change 事件
      // 解决办法就是每次使用完毕，把它的 value 清空
      this.$refs.file.value = ''
    }
  }
}
</script>

<style lang="less" scoped>
.info-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 44px;
  background-color: #fff;
  .van-nav-bar {
    height: 44px;
    background: #10955b;
    overflow: hidden;
    color: #ffffff;
    /deep/ .van-nav-bar__title {
      font-size: 20px;
      color: #ffffff;
    }
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
  }
  .info {
    position: relative;
    margin: 13px 22px 0 20px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: 100px;
      color: rgba(16, 16, 16, 100);
      font-size: 18px;
      text-align: left;
      font-family: PingFangSC-regular;
    }
    .middle {
      width: 150px;
      margin-left: 50px;
      text-align: right !important;
      img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
      }
      color: rgba(16, 16, 16, 100);
      font-size: 18px;
      text-align: left;
      font-family: PingFangSC-regular;
    }

    .right {
      width: 10px;
      height: 10px;
      border-top: 2px solid #000000;
      border-right: 2px solid #000000;
      transform: rotate(45deg);
    }
    .underline {
      position: absolute;
      top: 56px;
      width: 342px;
      height: 1px;
      background: #cccccc;
    }
  }
}
</style>