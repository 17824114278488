<template>
  <div class="update-gender">
    <van-picker
     :class="{elder:$store.state.user.elderModel}"
      show-toolbar
      title="选择性别"
      :columns="columns"
      :default-index="value.sex - 1"
      @cancel="$emit('close')"
      @confirm="onConfirm"
      @change="onPickerChange"
    />
  </div>
</template>

<script>
import { postUserInfo } from '@/api/me'

export default {
  name: 'UpdateGender',
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns: ['男', '女'],
      localGender: this.value.sex
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async onConfirm() {
      this.$toast.loading({
        message: '保存中...',
        forbidClick: true, // 禁止背景点击
        duration: 0 // 持续展示
      })

      try {
        const localGender = this.localGender

        await postUserInfo({
          realName: this.value.realName,
          avatar: this.value.avatar,
          email: this.value.email,
          phone: this.value.phone,
          birthday: this.value.birthday,
          sex: this.localGender
        })

        // 更新视图
        this.$emit('succeed')
        // 关闭弹层
        this.$emit('close')

        // 提示成功
        this.$toast.success('更新成功')
      } catch (err) {
        this.$toast.fail('更新失败')
      }
    },

    onPickerChange(picker, value, index) {
      this.localGender = index + 1
    }
  }
}
</script>

<style scoped lang="less">
.elder /deep/ .van-picker__cancel, .elder /deep/ .van-picker__title,.elder /deep/ .van-picker__confirm  {
 font-size: 18px;
}

/deep/.van-picker {
  .van-picker__cancel,
  .van-picker__confirm {
    color: #10955b;
  }
}

</style>
