<template>
  <div class="update-name">
    <!-- 导航栏 -->
    <van-nav-bar
     :class="{elder:$store.state.user.elderModel}"
      class="nav-bar"
      title="设置邮箱"
      left-text="取消"
      right-text="完成"
      @click-left="$emit('close')"
      @click-right="onConfirm"
    />
    <!-- /导航栏 -->
    <!-- 输入框 -->
    <div class="field-wrap">
      <van-form ref="email">
        <van-field
          border
          left-icon="free-postage"
          v-model="email"
          name="pattern"
          placeholder="请输入邮箱"
          :rules="[{ pattern, message: '请输入正确邮箱' }]"
        />
      </van-form>
    </div>
    <!-- /输入框 -->
  </div>
</template>

<script>
import { postUserInfo } from '@/api/me'
export default {
  name: 'UpdateName',
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      email: this.value.email,
      pattern: /^\w+@\w+(\.\w+)+$/
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
     onConfirm() {
      this.$refs.email
        .validate()
        .then(async () => {
          this.$toast.loading({
            message: '保存中...',
            forbidClick: true, // 禁止背景点击
            duration: 0 // 持续展示
          })

          try {
            const email = this.email
            if (!email.length) {
              this.$toast('邮箱不能为空')
              return
            }

            await postUserInfo({
              realName: this.value.realName,
              avatar: this.value.avatar,
              email: email,
              phone: this.value.phone,
              birthday: this.value.birthday,
              sex: this.value.sex
            })

            // 更新视图
            this.$emit('succeed')

            // 关闭弹层
            this.$emit('close')

            // 提示成功
            this.$toast.success('更新成功')
          } catch (err) {
            this.$toast.fail('更新失败')
          }
        })
        .catch(() => {
          return
        })
    }
  }
}
</script>

<style scoped lang="less">
.elder /deep/ .van-nav-bar__text, .elder /deep/ .van-nav-bar__title  {
 font-size: 18px;
}
.field-wrap {
  padding: 20px;
}
.nav-bar /deep/ .van-nav-bar__text {
  color: #10955b;
}
</style>