<template>
  <div class="update-name">
    <!-- 导航栏 -->
    <van-nav-bar
    class="nav-bar"
    :class="{elder:$store.state.user.elderModel}"
      title="设置昵称"
      left-text="取消"
      right-text="完成"
      @click-left="$emit('close')"
      @click-right="onConfirm"
    />
    <!-- /导航栏 -->
    <!-- 输入框 -->
    <div class="field-wrap">
      <van-field
      class="field"
       left-icon="user-o"
        v-model.trim="localName"
        rows="2"
        autosize
        type="textarea"
        maxlength="8"
        placeholder="请输入昵称"
        show-word-limit
      />
    </div>
    <!-- /输入框 -->
  </div>
</template>

<script>
import { postUserInfo } from '@/api/me'
export default {
  name: 'UpdateName',
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localName: this.value.realName
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async onConfirm() {
      this.$toast.loading({
        message: '保存中...',
        forbidClick: true, // 禁止背景点击
        duration: 0 // 持续展示
      })

      try {
        const localName = this.localName
        if (!localName.length) {
          this.$toast('昵称不能为空')
          return
        }

       await postUserInfo({
          realName: localName,
          avatar: this.value.avatar,
          email: this.value.email,
          phone: this.value.phone,
          birthday: this.value.birthday,
          sex: this.value.sex
        })
      

        // 更新视图
        this.$emit('succeed')

        // 关闭弹层
        this.$emit('close')

        // 提示成功
        this.$toast.success('更新成功')
        
      } catch (err) {
          console.log(err);
        this.$toast.fail('更新失败')
      }
    }
  }
}
</script>

<style scoped lang="less">
.elder /deep/ .van-nav-bar__text, .elder /deep/ .van-nav-bar__title  {
 font-size: 18px;
}

.field-wrap {
  padding: 20px;
}
.nav-bar /deep/ .van-nav-bar__text {
  color: #10955b;

}
</style>
