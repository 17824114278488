<template>
  <div class="update-photo">
    <img class="img" :src="img" ref="img" />

    <div class="toolbar">
      <div class="cancel" @click="$emit('close')">取消</div>
      <div class="confirm" @click="onConfirm">完成</div>
    </div>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import { postUserInfo, UploadingImg } from '@/api/me'

export default {
  name: 'UpdatePhoto',
  components: {},
  props: {
    img: {
      type: [String, Object],
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cropper: null
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    const image = this.$refs.img
    this.cropper = new Cropper(image, {
      viewMode: 1,
      dragMode: 'move',
      aspectRatio: 1,
      cropBoxMovable: false,
      cropBoxResizable: false,
      background: false
    })
  },
  methods: {
    onConfirm() {
      // 服务端的裁切使用 getData 方法获取裁切参数
      // 客户端的裁切使用 getCroppedCanvas 获取裁切的文件对象
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        this.postUserInfo(blob)
      })
    },

    async postUserInfo(blob) {
      this.$toast.loading({
        message: '保存中...',
        forbidClick: true, // 禁止背景点击
        duration: 0 // 持续展示
      })
      try {
        // 如果接口要求 Content-Type 是 multipart/form-data
        // 必须传递 FormData 对象
        const File1 = new File([blob], `${Date.parse(new Date())}.png`, {
          type: blob.type
        })
        const fd = new FormData()
        fd.append('file', File1)
        console.log(blob)
        UploadingImg(fd).then((res) => {
          postUserInfo({
            realName: this.value.realName,
            avatar: res.message,
            email: this.value.email,
            phone: this.value.phone,
            birthday: this.value.birthday,
            sex: this.value.sex
          }).then((res) => {
            // 关闭弹出层
            this.$emit('close')

            // 更新视图
            this.$emit('succeed')
          })
        })

     
        this.$toast.success('更新成功')
       
      } catch (err) {
        this.$toast.fail('更新失败')
      }
    }
  }
}
</script>

<style scoped lang="less">
.update-photo {
  background-color: #000;
  height: 100%;
  .toolbar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    .cancel,
    .confirm {
      width: 90px;
      height: 90px;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
}
.img {
  display: block;
  max-width: 100%;
}
</style>
