import { render, staticRenderFns } from "./update-photo.vue?vue&type=template&id=828c56fc&scoped=true&"
import script from "./update-photo.vue?vue&type=script&lang=js&"
export * from "./update-photo.vue?vue&type=script&lang=js&"
import style0 from "./update-photo.vue?vue&type=style&index=0&id=828c56fc&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "828c56fc",
  null
  
)

export default component.exports