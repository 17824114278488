<template>
  <div class="update-birthday">
    <!--
      currentDate 双向绑定了日期选择器
      min-date: 可选的最小日期
      max-date: 可选的最大日期
     -->
    <van-datetime-picker
      :class="{elder:$store.state.user.elderModel}"
      v-model="currentDate"
      type="date"
      :min-date="minDate"
      :max-date="maxDate"
      @cancel="$emit('close')"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { postUserInfo } from '@/api/me'
import dayjs from 'dayjs'

export default {
  name: 'UpdateBirthday',
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(this.value.birthday)
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async onConfirm() {
      this.$toast.loading({
        message: '保存中...',
        forbidClick: true, // 禁止背景点击
        duration: 0 // 持续展示
      })

      try {
        const currentDate = dayjs(this.currentDate).format('YYYY-MM-DD')

        await postUserInfo({
          realName: this.value.realName,
          avatar: this.value.avatar,
          email: this.value.email,
          phone: this.value.phone,
          birthday: currentDate,
          sex: this.value.sex
        })

        // 更新视图
       this.$emit('succeed')

        // 关闭弹层
        this.$emit('close')

        // 提示成功
        this.$toast.success('更新成功')
      } catch (err) {
        this.$toast.fail('更新失败')
      }
    }
  }
}
</script>

<style scoped lang="less">
.elder /deep/ .van-picker__cancel, .elder /deep/ .van-picker__confirm{
  font-size: 18px;
}
/deep/.van-picker {
  .van-picker__cancel,
  .van-picker__confirm {
       color: #10955b;
  }
}
</style>
